<template>
  <footer class="footer">
    © 2023
  </footer>
</template>

<script>
export default {

};
</script>

<style lang="scss" scoped>
.footer{
  display: flex;
  justify-content: center;
  align-items: center;
  background: black;
  color: #fff;
  font-size: 14px;
  height: 40px;
}
</style>
