<template>
  <div class="foryou">
    <div class="left">
      为您提供咨询，制定投放方案
      <br>

为您监测效果，优化曝光效果
    </div>
    <div class="right">
      让您的广告以低成本，高效率的方式登上各大主流页面。
      <br>

      您与最前沿的营销方式仅一步之遥，我们帮您用科技铸就品牌，用专业
      <br>
      产生效益
    </div>
  </div>
</template>

<script>
export default {

};

</script>

<style lang="scss" scoped>
.foryou{
  background: rgb( 255,191,35);
  height: 341px;
  display: flex;
  justify-content: center;
  align-items: center;
   .left{
    font-size: 35px;
   }
   .right{
    margin-left: 125px;
    font-size: 16px;
   }
}
</style>
